<template>
  <div>
    <div class="search-input-div">
      <van-search class="search-input" v-model="query.stuName" show-action placeholder="请输入关键词查询" @search="toQuery">
        <template #action>
          <div @click="toQuery">搜索</div>
        </template>
      </van-search>
    </div>
    <!-- 卡片 -->
    <van-list v-model="loading" :immediate-check="false" offset="30" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-cell-group v-else inset style="margin-top:15px" v-for="(row,index) in data" :key="index">
        <van-cell>
          <template #title>
            <span class="custom-title hidden-text"> <span style="color:rgba(35, 171, 114, 1)">{{row.xn +'年'+codeConvertLabel(row.xq,dict.xj_xqgl)}}</span></span>
          </template>
          <template #label>
            <div class="custom-centent margin6 hidden-text95">
              <span @click="toStuDeatil(row)"> <b style="font-size:15px;color:black">{{row.stuName}}</b> {{  row.stuNo}}</span>
            </div>
            <div class="custom-centent margin6 hidden-text95">
              <svg-icon icon-class="date" size="15" /> 院系: {{row.collName}}
            </div>
            <div class="custom-centent margin6 hidden-text95">
              <van-icon name="user-o" /> 专业: {{ row.specName }}
            </div>
            <div class="custom-centent margin6 hidden-text95">
              <van-icon name="manager-o" /> 班级: {{ row.className }}
            </div>
          </template>
          <template #right-icon>
            <span class="table-detail-btn detail-pos" @click="toDetail(row)" style="color:#B2B2B2"> 详情
              <van-icon name="arrow" />
            </span>
          </template>
        </van-cell>
        <van-cell id="opt-style">
          <template #title>
            <span class="custom-centent"> 成绩：</span>
            <span style="font-weight: bold;color:#23AB72;font-size:17px;line-height:27px">{{row.score}}</span>
          </template>
          <template #right-icon>
            <span class="custom-centent">校/院/专/班：</span>
            <span style="font-weight: bold;color:#23AB72;font-size:17px;line-height:27xpx">
              {{ row.schoolRank?row.schoolRank:'--' }}/{{ row.collRank?row.collRank:'--' }}/{{ row.specRank?row.specRank:'--' }}/{{ row.classRank?row.classRank:'--' }}
            </span>
          </template>
        </van-cell>
      </van-cell-group>
    </van-list>
  </div>
</template>

<script>
import initData from "@/mixins/initData";
export default {
  name: "StuScoreManage",
  mixins: [initData],
  dicts: ['xj_nj', 'xj_xqgl', 'xj_shzt'],
  data() {
    return {}
  },
  created() { },
  methods: {
    beforeInit() {
      this.url = "api/evaluation/final/score";
      this.sort = ['score,desc', 'schoolRank,asc', 'collRank,asc', 'specRank,asc', 'classRank,asc', 'id,desc'];
      return true;
    },
    toStuDeatil(data) {
      this.$router.push({
        path: "/js/stu/detail",
        query: { stu: btoa(data.stuNo) },
      });
    },
    toDetail(data) {
      this.$router.push({
        path: "/xc/score/manage/detail",
        query: { id: data.id },
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.detail-pos {
  position: absolute;
  top: 50%;
  right: 15px;
}
</style>